import React from 'react'
import {
    LeafDialog,
    LeafDialogProps,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { DialogContent } from '@mui/material'
import BaselineAssessment, {
    BaselineAssessmentResponse
} from '../BaselineAssessment'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import useGetRecommendedJourneys from '../../hooks/useGetRecommendedJourneys'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useGetRecommendedJourneysQuery } from '../../../graphql/generated/autogenerated'

const BaselineAssessmentDialog: React.FC<LeafDialogProps> = ({ ...props }) => {
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()

    const onAssessmentComplete = (response: BaselineAssessmentResponse) => {
        props.onClose()
        if (response.completed) {
            //TODO: show success alert when completed or error alert when closed in error
        }
    }

    return (
        <LeafDialog
            {...props}
            fullScreen
            PaperProps={{
                sx: {
                    background: theme.gradient.main,
                    padding: 0
                }
            }}
        >
            <PageLayout maxWidth={'md'}>
                <DialogContent sx={{ mt: isSmallScreen ? 4 : 8 }}>
                    <BaselineAssessment onComplete={onAssessmentComplete} />
                </DialogContent>
            </PageLayout>
        </LeafDialog>
    )
}

export default BaselineAssessmentDialog
