import {
    Box,
    Card,
    CardContent,
    Fade,
    Skeleton,
    Stack,
    SxProps
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem
} from '../../../graphql/generated/autogenerated'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    CoreAlert,
    CoreTypography,
    ErrorScreenVariant,
    LeafErrorCode
} from '@thriveglobal/thrive-web-leafkit'
import AssessmentStep, { AssessmentStepStyleProps } from './AssessmentStep'
import { ApolloError } from '@apollo/client'
import PrivacyNotice from '../../../components/PrivacyNotice'

const messages = defineMessages({
    currentStepHeader: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    },
    errorCodeMessage: {
        defaultMessage:
            "We're having trouble loading this page, please refresh the page or come back later.",
        description:
            'Description of technical difficulties. Asking the user to try again later.'
    },
    outroHeader: {
        defaultMessage: 'Thank you for investing in your well-being.',
        description: 'Thank you message for the end of the survey.'
    },
    outroSubHeader: {
        defaultMessage:
            'Your honest responses will help guide your experience.',
        description: "Sub header describing the impact of the user's action."
    },
    outroButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Label for button that closes the survey.'
    }
})

export interface AssessmentResponse {
    completed: boolean
    error?: any
    specificError?: AssessmentAttemptError
    assessmentAttempt?: AssessmentAttemptSuccess
}

export interface AssessmentProps extends AssessmentStepStyleProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
    onCompleteAssessment: (response: AssessmentResponse) => void
    wrapperVariant?: 'card' | 'nowrapper'
    sx?: SxProps
}

const Assessment: React.FC<AssessmentProps> = ({
    assessmentAttempt,
    assessmentAttemptError,
    error,
    loading,
    assessmentItems,
    onCompleteAssessment,
    wrapperVariant = 'card',
    sx,
    ...assessmentStepperStyleProps
}) => {
    const intl = useIntl()
    const [step, setStep] = useState<number>(1)
    const [latestAssessmentResponse, setLatestAssessmentResponse] =
        useState<AssessmentAttemptSuccess>()

    const assessmentError = useMemo(() => {
        return (
            error ||
            (assessmentAttemptError &&
                assessmentAttemptError?.errorCode ===
                    'ASSESSMENT_CMS_ASSESSMENT_NOT_FOUND') ||
            assessmentAttempt?.allQuestions?.length === 0
        )
    }, [assessmentAttempt?.allQuestions?.length, assessmentAttemptError, error])

    function completeWithoutErrors() {
        Avo.surveyCompleted({
            featureType: 'assessment',
            activityType: 'progress_assessment_completed',
            isOnboarding: true,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant
        })
        onCompleteAssessment({
            completed: true,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    function completeWithErrors() {
        onCompleteAssessment({
            completed: false,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    const nextStep = () => {
        if (step === assessmentItems?.length) {
            //if final step
            completeWithoutErrors()
        } else {
            //else next step
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        setStep(step - 1)
    }

    const AssessmentWrapper = useCallback(
        (props) => {
            switch (wrapperVariant) {
                case 'card':
                    return (
                        <Card sx={sx}>
                            <CardContent>{props.children}</CardContent>
                        </Card>
                    )
                case 'nowrapper':
                    return props.children
            }
        },
        [sx, wrapperVariant]
    )

    return (
        <AssessmentWrapper>
            <Stack textAlign={'left'}>
                {loading && (
                    <Stack mt={2} gap={1} display={'flex'} width={'100%'}>
                        <Skeleton
                            variant={'text'}
                            sx={{ fontSize: '1.4rem' }}
                            width={'80px'}
                        />
                        <Skeleton
                            variant={'text'}
                            sx={{ fontSize: '2rem' }}
                            width={'33%'}
                        />
                        <Skeleton
                            variant={'rounded'}
                            sx={{ maxWidth: '100%' }}
                            width={'100%'}
                            height={'44px'}
                        />
                    </Stack>
                )}
                {error && (
                    <LeafErrorCode
                        variant={ErrorScreenVariant.ServerError}
                        navigationButtonProps={{
                            onClick: completeWithErrors
                        }}
                    />
                )}
                {!loading &&
                    !assessmentError &&
                    assessmentItems?.length > 0 && (
                        <>
                            <CoreTypography variant={'overline'}>
                                {intl.formatMessage(
                                    messages.currentStepHeader,
                                    {
                                        currentStep: step,
                                        totalSteps: assessmentItems?.length
                                    }
                                )}
                            </CoreTypography>
                            {assessmentItems.map((assessmentItem, index) => {
                                const isActiveStep = index + 1 === step
                                return (
                                    <Fade
                                        key={index}
                                        in={isActiveStep}
                                        timeout={{
                                            enter: 300,
                                            exit: 0
                                        }}
                                    >
                                        <Box>
                                            <AssessmentStep
                                                assessmentItem={assessmentItem}
                                                step={step}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                assessmentAttempt={
                                                    assessmentAttempt
                                                }
                                                setLatestAssessmentResponse={
                                                    setLatestAssessmentResponse
                                                }
                                                sx={
                                                    isActiveStep
                                                        ? {
                                                              visibility:
                                                                  'visible',
                                                              display: 'flex'
                                                          }
                                                        : {
                                                              visibility:
                                                                  'hidden',
                                                              display: 'none'
                                                          }
                                                }
                                                {...assessmentStepperStyleProps}
                                            />
                                        </Box>
                                    </Fade>
                                )
                            })}
                            <PrivacyNotice />
                        </>
                    )}
            </Stack>
        </AssessmentWrapper>
    )
}

export default Assessment
